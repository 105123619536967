import React from "react"
import ReactGA from "react-ga"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import social from "../../data/social.json"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  links: {
    margin: "0.5rem 0.25rem",
    "& img": {
      margin: 0,
    },
  },
}))

const Social = () => {
    const classes = useStyles();
  return (
    <Grid container alignItems="center">
      {social.map(({ id, name, link, icon }) => (
        <Link
          key={id}
          href={link}
          className={classes.links}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`follow me on ${name}`}
          onClick={()=>ReactGA.event({
            category: "Social",
            action: `Accessed ${name}`,
          })}
        >
          <img width="32" height="32" src={icon} alt={name} />
        </Link>
      ))}
    </Grid>
  )
}

export default Social
