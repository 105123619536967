import React from "react"
import Link from "../common/Link"
import Tabs from "./Tabs"
import Drawer from "./Drawer"
import Container from "../common/Container"
import WebsiteLogo from "../../assets/images/website-logo.svg"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    //toolbar minimum height
    ...theme.mixins.toolbar,
    marginBottom: "4em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3em",
    },
  },
  logoContainer: {
    marginTop: 5,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  logo: {
    height: "3em",
    [theme.breakpoints.down("md")]: {
      height: "2.5em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2.5em",
    },
  },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

const Header = () => {
  const classes = useStyles()
  return (
    <>
      <AppBar position="absolute" color="transparent" elevation={0}>
        <Container>
          <Toolbar disableGutters>
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  disableRipple
                  variant="text"
                  classes={{ text: classes.logoContainer }}
                  style={{ height: "100%" }}
                  component={Link}
                  to="/"
                >
                  <img src={WebsiteLogo} className={classes.logo} alt="website logo" />
                </Button>
              </Grid>
              <Grid item>
                <Hidden mdUp>
                  <Drawer />
                </Hidden>
                <Hidden smDown>
                  <Tabs />
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </>
  )
}

export default Header
