import React from "react"
import Form from "./Form"
import Container from "../../components/common/Container"
import contactOverlay from "../../assets/illustrations/contact-overlay.svg"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    backgroundImage: `url(${contactOverlay})`,
    backgroundSize: "contain",
    backgroundPosition: "left top",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.common.primary,
  },
  illustration: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "85vw",
      marginTop: "2rem",
    },
  },
}))

const About = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smaller = useMediaQuery(theme.breakpoints.down("sm"))
  const smallest = useMediaQuery(theme.breakpoints.down("xs"))
  const IllustrationJSX = () => (
    <StaticImage
      src="../../assets/illustrations/contact.svg"
      alt="contact illustration"
      placeholder="tracedSVG"
      imgClassName={classes.illustration}
    />
  )

  return (
    <div>
      <Container>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          direction={smaller ? "column" : "row"}
          style={{ padding: "4rem 0" }}
        >
          <Grid
            item
            sm={12}
            md={6}
            align="center"
            style={{ paddingRight: smaller ? "0rem" : "5rem" }}
          >
            {smaller ? <IllustrationJSX /> : <Form />}
          </Grid>
          <Grid
            item
            sm={12}
            md={6}
            style={{
              width: smallest ? "100%" : smaller ? "80%" : "100%",
              marginTop: smaller ? "5rem" : "0rem",
            }}
          >
            {smaller ? <Form /> : <IllustrationJSX />}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default About
