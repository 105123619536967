import React from "react"
import { Icon } from "@iconify/react"
import techStackIcons from "./projectData/icons"
import { Tooltip, IconButton, Grid, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"

const TechStack = ({ data = [] }) => {
  const theme = useTheme()
  const smallest = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <Grid container>
      {data.map((techIcon, i) => (
        <Grid item style={{ marginTop: "0.5em" }} key={i + 1}>
          <Tooltip title={techIcon?.title} placement="top">
            <IconButton
              aria-label={techIcon?.ariaLabel}
              size={smallest ? "small" : "medium"}
              style={{ borderRadius: 0, margin: "1em 0.5em" }}
              disableRipple
              disableFocusRipple
            >
              <Icon
                icon={techStackIcons[techIcon?.icon]}
                width={smallest ? "36" : "48"}
                height={smallest ? "36" : "48"}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}

export default TechStack
