import React, { useState } from "react"
import ReactGA from "react-ga"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import sections from "./sections.json"
import resume from "../../assets/files/Hassaan-Zuberi--Developer.pdf"

const useStyles = makeStyles(theme => ({
  tab: {
    ...theme.typography.tab,
    minWidth: 9,
    marginLeft: "20px",
    opacity: 1,
  },
  resume: {
    fontSize: "1rem",
    fontWeight: 500,
    marginLeft: "20px",
    height: "100%",
    padding: "6px 15px",
    backgroundColor: "transparent",
  },
  indicator: {
    backgroundColor: "transparent",
  },
}))

const Navigation = () => {
  const classes = useStyles()
  const [value, setValue] = useState(2)
  const handleChange = (_, newValue) => setValue(newValue)
  const analytics = () =>
    ReactGA.event({
      category: "Resume",
      action: "Accessed Resume",
    })
  return (
    <>
      <Grid container>
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="section navigation"
            classes={{ indicator: classes.indicator }}
          >
            {sections.map(section => (
              <Tab
                key={section.activeTabIndex}
                value={section.activeTabIndex}
                label={section.label}
                aria-controls={section.ariaControls}
                component={AnchorLink}
                href={section.link}
                className={classes.tab}
                disableRipple
                disableFocusRipple
                disableTouchRipple
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            className={classes.resume}
            component={Link}
            href={resume}
            onClick={analytics}
            alt="Hassaan's Resume"
            download
          >
            Resume
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default Navigation
