import React from "react"
import Project from "./Project"
import projectDataCollection from "./projectData/data.json"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Container from "@material-ui/core/Container"
import { Grid, Typography } from "@material-ui/core"

const Projects = () => {
  const {
    allFile: { edges: projectImages },
  } = useStaticQuery(graphql`
    {
      allFile {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Container>
        <Typography variant="h2" style={{ marginTop: "2em" }} gutterBottom>
          Projects
        </Typography>
        <Grid
          container
          spacing={5}
          style={{ marginTop: "1em", marginBottom: "6.5em" }}
        >
          {projectDataCollection.map(projectData => {
            const [image] = projectImages.filter(
              img => img.node.name === projectData.imageName
            )
            const projectImage = getImage(image.node)
            return (
              <Grid item lg={4} md={6} sm={6} xs={12} key={projectData.id}>
                <Project data={projectData} image={projectImage} />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default Projects
