import React from "react";
import ContentWrapper from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    style: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
    },
}))

const Container = (props) => {
    const classes = useStyles();
    return (
        <ContentWrapper className={classes.style}>
            {props.children}
        </ContentWrapper>
    )
}

export default Container