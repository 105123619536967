import React from "react"
import Footer from "../components/Footer"
import Intro from "../layout/Intro"
import About from "../layout/About"
import Contact from "../layout/contact"
import Projects from "../layout/projects"

const Index = () => {
  return (
    <>
      <section id="intro">
        <Intro />
      </section>
      <section id="projects" style={{ width: "100%" }}>
        <Projects />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default Index
