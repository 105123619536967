import React, { useState } from "react"
import ReactGA from "react-ga"
import { useFormik } from "formik"
import * as yup from "yup"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/core/styles"
import SendButton from "./SendButtonContent"

const useStyles = makeStyles(theme => ({
  button: {
    ...theme.typography.buttonText,
    backgroundColor: "#0074d9",
    color: "#fff",
    padding: "0.7rem 2rem",
    opacity: 0.85,
    width: "14rem",
    "&:hover": {
      opacity: 1,
      backgroundColor: "#0074d9",
    },
  },
}))

const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/

const validationSchema = yup.object({
  name: yup.string("Enter your fullname").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter your phone number")
    .matches(phoneRegExp, "10 digits US/Canada phone numbers only")
    .required("Phone number is required"),
  message: yup
    .string("Type your message here...")
    .required("Please type a message"),
})

const encodeFormData = formData => {
  return Object.keys(formData)
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`
    )
    .join("&")
}

const Form = () => {
  const classes = useStyles()
  const [isSubmitting, setSubmitting] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    type: "",
  })
  const handleAlertClose = (_, reason) => {
    if (
      (reason === "clickaway" && alert.type === "error") ||
      reason === "clickaway"
    )
      return
    setAlert({ ...alert, open: false })
  }
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      checkForSpam: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true)
      try {
        const submitForm = await fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: encodeFormData({ "form-name": "contact-Hassaan", ...values }),
        })
        if (!submitForm.ok)
          throw new Error("Form cannot be submitted. Please try again.")
        setSubmitting(false)
        setAlert({
          open: true,
          message:
            "Message has been sent successfully. Hassaan will be in touch with you in next 24 hours.",
          type: "success",
        })
        resetForm()
      } catch (err) {
        setSubmitting(false)
        setAlert({
          open: true,
          message:
            "Something went wrong! Please try again or send an email to mhazuberi@gmail.com",
          type: "error",
        })
        resetForm()
        console.error(`${err.message}`)
      }
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      data-netlify="true"
      netlify-honeypot="checkForSpam"
      name="contact-Hassaan"
      method="post"
      style={{ backgroundColor: "#fafafa", width: "100%" }}
    >
      <Grid container direction="column">
        <Grid item style={{ marginTop: "1em", width: "100%" }}>
          <TextField
            id="name"
            name="name"
            label="Full Name"
            value={formik.values.name}
            variant="outlined"
            color="secondary"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </Grid>
        <Grid item style={{ marginTop: "1em", width: "100%" }}>
          <TextField
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            variant="outlined"
            color="secondary"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
        </Grid>
        <Grid item style={{ marginTop: "1em", width: "100%" }}>
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            value={formik.values.phone}
            variant="outlined"
            color="secondary"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            fullWidth
          />
        </Grid>
        <Grid item style={{ marginTop: "1em", width: "100%" }}>
          <TextField
            id="message"
            name="message"
            value={formik.values.message}
            label="Message"
            variant="outlined"
            color="secondary"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            multiline
            fullWidth
            rows={7}
          />
          <input type="hidden" name="checkForSpam" />
        </Grid>
        <Grid item container>
          <Grid item style={{ marginTop: "1em" }}>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              onClick={()=>ReactGA.event({
                category: "Form",
                action: `Message has been sent.`,
              })}
            >
              {isSubmitting ? (
                <CircularProgress size={25} color="primary" />
              ) : (
                <SendButton text="SEND MESSAGE" />
              )}
            </Button>
            <Snackbar
              open={alert.open}
              autoHideDuration={alert.type === "success" ? 20000 : null}
              onClose={handleAlertClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleAlertClose}
                severity={alert.type}
                variant="filled"
                elevation={8}
                style={{ fontSize: "1rem" }}
              >
                {alert.message}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
