import semanticUi from "@iconify/icons-logos/semantic-ui"
import vsCode from "@iconify-icons/vscode-icons/file-type-vscode"
import html from "@iconify-icons/vscode-icons/file-type-html"
import git from "@iconify/icons-logos/git-icon"
import css from "@iconify-icons/logos/css-3"
import javascript from "@iconify-icons/logos/javascript"
import react from "@iconify-icons/logos/react"
import netlify from "@iconify-icons/logos/netlify"
import eslint from "@iconify-icons/logos/eslint"
import prettier from "@iconify-icons/logos/prettier"
import materialUi from "@iconify-icons/logos/material-ui"
import parcel from "@iconify-icons/emojione/package"
import firebase from "@iconify-icons/logos/firebase"
import sass from "@iconify-icons/logos/sass"


// A Basic Hashtable Implementation to access dynamic keys
// TechStack icons should have the same name
// as techStack[n].icon OR icon property in project's data.json file
const techStackIcons = {
  semanticUi,
  vsCode,
  git,
  html,
  css,
  javascript,
  react,
  netlify,
  eslint,
  prettier,
  parcel,
  materialUi,
  firebase,
  sass,
}

export default techStackIcons
