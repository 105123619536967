import React from "react"
import ReactGA from "react-ga"
import DesktopWindowsOutlinedIcon from "@material-ui/icons/DesktopWindowsOutlined"
import GitHubIcon from "@material-ui/icons/GitHub"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  button: {
    border: "none",
    borderRadius: 0,
    opacity: 0.75,
    paddingTop: 12.5,
    paddingBottom: 12.5,
    "&:hover": {
      opacity: 1,
    },
  },
}))

export const Live = props => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      startIcon={<DesktopWindowsOutlinedIcon />}
      href={props?.url}
      rel="noopener noreferrer"
      target="_blank"
      className={classes.button}
      onClick={()=>ReactGA.event({
            category: "Project",
            action: `Accessed ${props?.name} Live Webiste`,
          })}
      fullWidth
    >
      LIVE
    </Button>
  )
}
export const Code = props => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      startIcon={<GitHubIcon />}
      href={props?.url}
      rel="noopener noreferrer"
      target="_blank"
      className={classes.button}
      onClick={()=>ReactGA.event({
            category: "Project",
            action: `Accessed ${props?.name} Github Code`,
          })}
      fullWidth
    >
      CODE
    </Button>
  )
}
