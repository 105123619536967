import React, { useState, useEffect } from "react"
import ReactGA from "react-ga"
import { Live, Code } from "./ActionButtons.js"
import TechStack from "./TechStack"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AnchorLink from "react-anchor-link-smooth-scroll"
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded"
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Link,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  thumbnail: {
    height: 150,
  },
  cardTile: {
    cursor: "pointer",
  },
  largeImg: {
    height: 600,
  },
}))
const Project = ({ data, image }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smaller = useMediaQuery(theme.breakpoints.down("sm"))

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      //add a history object based on current window location to
      //preserve current window location
      window.history.pushState(null, "", window.location.href)
      //setup the listener to detect back button
      window.onpopstate = () => {
        //back button pressed
        //now add the history object to get back to the same window state that
        //was created before the popstate event
        window.history.pushState(null, "", window.location.href)
        //close the dialog
        setOpen(false)
      }
    }
  }, [open])

  return (
    <>
      <Card elevation={2}>
        <CardActionArea onClick={() => setOpen(true)}>
          <CardMedia>
            <GatsbyImage image={image} alt={data.altText} />
          </CardMedia>
        </CardActionArea>
        <CardContent>
          <Typography
            variant="h6"
            style={{ marginTop: "1em" }}
            onClick={() => {
              setOpen(true)
            }}
            className={classes.cardTile}
            gutterBottom
          >
            {data.label}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {data.excerpt}
            <Link
              href="#"
              onClick={e => {
                e.preventDefault()
                setOpen(true)
                ReactGA.event({
                  category: "Projects",
                  action: `Accessed ${data.label} Project Details`,
                })
              }}
              style={{
                textDecoration: "none",
                color: "#6c63ff",
                display: "block",
              }}
            >
              Read More
            </Link>
          </Typography>
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={6}>
              <Live url={data.live} name={data.label}/>
            </Grid>
            <Grid item xs={6}>
              <Code url={data.code} name={data.label}/>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          //whenever dialog opens, a new window location state is pushed
          //To revert the new window state, whenever dialog is closed without pressing
          //the back button
          window.history.back()
          //this is will revive the back button to default
        }}
        aria-labelledby="project-details"
        fullScreen={smaller ? true : false}
        style={{ zIndex: 1302 }}
        scroll="body"
        maxWidth={smaller ? "sm" : "lg"}
      >
        <Card>
          <GatsbyImage image={image} alt={data.altText} placeholder="BLURRED" />
          <DialogTitle disableTypography style={{ marginTop: "3em" }}>
            <Typography variant={smaller ? "h6" : "h4"}>
              {data.label}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant={smaller ? "subtitle1" : "body2"}
              style={{ lineHeight: 1.75 }}
              gutterBottom
            >
              {data.description}
            </Typography>
            <Divider style={{ marginTop: "1.5em", marginBottom: "1em" }} />
            <Typography variant="h6" gutterBottom>
              Tech Stack
            </Typography>
            <TechStack data={data.techStack} />
            <Divider style={{ marginTop: "1.5em", marginBottom: "1em" }} />
            <Typography variant="h6" gutterBottom>
              Libraries, APIs, Packages and Techniques
            </Typography>
            {data.tags.map((tag, i) => (
              <Chip
                variant="default"
                size="small"
                label={tag}
                key={i}
                style={{ margin: "1em 0.5em" }}
              />
            ))}
            <Divider style={{ marginTop: "1.5em", marginBottom: "1em" }} />
            <Typography variant="h6" gutterBottom>
              Possible Improvements
            </Typography>
            <List aria-label="possible/future project improvements">
              {data.improvements.map((improvementItem, i) => (
                <ListItem key={i} alignItems="flex-start">
                  <ListItemIcon>
                    <ArrowRightRoundedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={improvementItem}
                    primaryTypographyProps={{ variant: "subtitle1" }}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              component={AnchorLink}
              href="#projects"
              style={{ backgroundColor: "transparent" }}
              onClick={() => setOpen(false)}
            >
              Back to Projects
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
    </>
  )
}

export default Project
