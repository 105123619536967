import React from "react"
import ReactGA from "react-ga"
import Conatiner from "../common/Container"
import footerIllustration from "../../assets/illustrations/footer.svg"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import Social from "../common/Social"

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: "14rem 0 4rem",
    backgroundImage: `url(${footerIllustration})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.up("xl")]: {
      padding: "28rem 0 4rem 0",
    },
  },
  details: {
    color: "#212121",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2rem",
    },
  },
  links: {
    margin: "0 0.5rem",
    "& img": {
      margin: 0,
    },
  },
  inlineStyle: {
    display: "inline-block",
  },
}))

const Footer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const smaller = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div className={classes.wrapper}>
      <Conatiner>
        <Grid
          container
          alignItems={smaller ? "center" : "flex-end"}
          justify="space-between"
          direction={smaller ? "column" : "row"}
          style={{ textAlign: smaller ? "center" : "left" }}
        >
          <Grid item className={classes.details}>
            <Typography variant="h4">Hassaan Zuberi</Typography>
            <Typography
              variant="body2"
              component={Link}
              href="mailto:mhazuberi@gmail.com"
              underline="none"
              onClick={() =>
                ReactGA.event({
                  category: "Email",
                  action: "Email Resume",
                })
              }
            >
              mhazuberi@gmail.com
            </Typography>
            <span style={{ display: "block", marginTop: "2em" }}>
              © All rights are reserved | {new Date().getFullYear()} |{" "}
              <span className={classes.inlineStyle}>
                Built with{" "}
                <Link
                  href="https://www.gatsbyjs.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="none"
                >
                  Gatsby
                </Link>
                .
              </span>
              <span> </span>
              <span className={classes.inlineStyle}>
                {" "}
                Powered by{" "}
                <Link
                  href="https://www.netlify.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="none"
                >
                  Netlify
                </Link>
                .
              </span>
            </span>
          </Grid>
          <Grid item>
            <Social />
          </Grid>
        </Grid>
      </Conatiner>
    </div>
  )
}

export default Footer
